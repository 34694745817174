<template>
  <div>
    <EITHeader />
    <EITIndex />
    <EITFooter />
  </div>
</template>

<script>
import EITHeader from '@/components/eye/EITHeader.vue'
import EITIndex from '@/components/eye/EITIndex.vue'
import EITFooter from '@/components/eye/EITFooter.vue'

export default {
  components: {
    EITHeader,
    EITIndex,
    EITFooter
  }
}
</script>
