<template>
  <v-row style="padding-top: 20px; padding-left: 20px; margin-bottom: 50px; width: 100%;">
    <v-col cols="12" xs="12" sm="12" md="6" lg="4" style="padding-top: 50px; padding-left: 50px;">
      <div>
        <template>
          <v-row>
            <v-col class="my-border-rounded" cols="12">
              <v-row style="margin-top:0px">
                <v-col class="d-flex my-compact" cols="12">
                  <P class="my-heading-1">Right Eye</P>
                </v-col>
              </v-row>
              <v-row style="margin-top:0px">
                <v-col class="d-flex my-compact" cols="12" for="enabledR">
                  <span class="my-label-description">Active treatment: </span>
                  <v-switch id="enabledR" v-model="R_Enable" @change="" :label="`${Rinj2}`"></v-switch>
                </v-col>
              </v-row>
              <div v-if="R_Enable">
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <v-combobox :disabled="R_Extended" v-model="R_Interval" :items="items" label="Treatment interval in weeks"
                      clearable :rules="nameRules"></v-combobox>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <v-menu id="lastTreatDtR" v-model="menu1" :close-on-content-click="false"
                      transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on, attrs1 }">
                        <v-text-field v-model="R_LastDate" persistent-hint prepend-icon="" v-bind="attrs1"
                          label="Last Treatment Date" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="R_LastDate" no-title @input="menu1 = false" :max="todayDate"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <p class="my-label-description">{{ getRightEyeMsg1() }}</p>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <span class="my-label-description" for="treatedTodayR">Treatment Today:</span>
                    <v-switch id="treatedTodayR" v-model="R_TreatedToday" :label="`${Rinj1}`"></v-switch>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <span class="my-label-description" for="extendedR">Treatment Interval Change</span>
                    <v-switch id="extendedR" v-model="R_Extended" :label="`${R_Extended_Lable}`"></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="R_Extended" style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <v-combobox v-model="R_New_Interval" :items="items" label="New interval in weeks"
                      clearable :rules="nameRules"></v-combobox>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <p class="my-label-description">{{ getRightEyeMsg2() }}</p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="6" lg="4" style="padding-top: 50px; padding-left: 50px;">
      <div>
        <template>
          <v-row>
            <v-col class="my-border-rounded" cols="12">
              <v-row style="margin-top:0px">
                <v-col class="d-flex my-compact" cols="12">
                  <P class="my-heading-1">Left Eye</P>
                </v-col>
              </v-row>
              <v-row style="margin-top:0px">
                <v-col class="d-flex my-compact" cols="12" for="enabledL">
                  <span class="my-label-description">Active treatment: </span>
                  <v-switch id="enabledL" v-model="L_Enable" :label="`${Linj2}`"></v-switch>
                </v-col>
              </v-row>
              <div v-if="L_Enable">
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <v-combobox :disabled="L_Extended" v-model="L_Interval" :items="items" label="Treatment interval in weeks"
                      clearable :rules="nameRules"></v-combobox>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <v-menu id="lastTreatDtR" v-model="menu2" :close-on-content-click="false"
                      transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on, attrs1 }">
                        <v-text-field v-model="L_LastDate" persistent-hint prepend-icon="" v-bind="attrs1"
                          label="Last Treatment Date" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="L_LastDate" no-title @input="menu2 = false" :max="todayDate"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <p class="my-label-description">{{ getLeftEyeMsg1() }}</p>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <span class="my-label-description" for="treatedTodayL">Treatment Today:</span>
                    <v-switch id="treatedTodayL" v-model="L_TreatedToday" :label="`${Linj1}`"></v-switch>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <span class="my-label-description" for="extendedL">Treatment Interval Change</span>
                    <v-switch id="extendedL" v-model="L_Extended" :label="`${L_Extended_Lable}`"></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="L_Extended" style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <v-combobox v-model="L_New_Interval" :items="items" label="New interval in weeks"
                      clearable :rules="nameRules"></v-combobox>
                  </v-col>
                </v-row>
                <v-row style="margin-top:0px">
                  <v-col class="d-flex my-compact" cols="12">
                    <p class="my-label-description">{{ getLeftEyeMsg2() }}</p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="6" lg="4" style="padding-top: 50px; padding-left: 50px;">
      <div>
        <template>
          <v-row>
            <v-col cols="12" class="my-border-rounded-2">
              <h3>For Clinician</h3>
              <hr style="margin-bottom: 10px;" />
              <p class="multiline">
                {{ getDoctorMessage() }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="margin-top: 20px;" class="my-border-rounded-2">
              <h3>For Patient</h3>
              <hr />
              <P>
                Next 3 subsequent treatment dates
              </P>
              <p class="multiline">
                {{ getPatientMessage() }}
              </p>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: (vm) => ({
    R_Enable: true,
    R_Interval: 4, // in weeks
    R_LastDate: null,
    R_TreatedToday: false,
    R_Extended: false,
    R_New_Interval: 6,

    L_Enable: true,
    L_Interval: 4, // in weeks
    L_LastDate: null,
    L_TreatedToday: false,
    L_Extended: false,
    L_New_Interval: 6,

    todayDate: new Date().toISOString().substr(0, 10),

    items: ['4', '6', '8', '10', '12', '14', '16'],
    menu1: false,
    menu2: false,
    nameRules: [
      v => !!v || 'Interval is required', // 必填项验证
      v => /^[1-9]\d*$/.test(v) || 'Must be a positive integer' // 正整数验证
    ]
  }),

  computed: {
    Rinj1() {
      if (this.R_TreatedToday) {
        return 'yes';
      } else {
        return 'no';
      }
    },
    Linj1() {
      if (this.L_TreatedToday) {
        return 'yes';
      } else {
        return 'no';
      }
    },
    Rinj2() {
      if (this.R_Enable) {
        return 'yes';
      } else {
        return 'no';
      }
    },
    Linj2() {
      if (this.L_Enable) {
        return 'yes';
      } else {
        return 'no';
      }
    },
    R_Extended_Lable() {
      if (this.R_Extended) {
        return 'yes';
      } else {
        return 'no';
      }
    },
    L_Extended_Lable() {
      if (this.L_Extended) {
        return 'yes';
      } else {
        return 'no';
      }
    }
  },

  methods: {
    /**
     * For a given day counter i, this function returns a description of weeks and days
     * e.g. i=8 => 1 week and 2 days, i=1 => 1 day
     */
    getDayCounterMessage(i) {
      var dayCount = i % 7;
      var weekCount = Math.round(parseInt(i / 7));
      let dayLabel = dayCount == 1 ? 'day' : 'days'; // 1 day, 2 days
      let weekLabel = weekCount == 1 ? 'week' : 'weeks'; // 1 week, 2 weeks

      if (i < 7) {
        return `${i} ${dayLabel}`;
      } else if (i % 7 == 0) {
        this.Rlast = `${weekCount} ${weekLabel}`; //Math.round(parseInt(i / 7)) + 'weeks';
        return `${weekCount} ${weekLabel}`; //Math.round(parseInt(i / 7)) + 'weeks since last treatment';
      } else {
        this.Rlast = Math.round(parseInt(i / 7)) + 'weeks' + i % 7 + 'days';
        return `${(i/7).toFixed(1)} weeks (${weekCount} ${weekLabel} and ${dayCount} ${dayLabel})`; // Math.round(parseInt(i / 7)) + 'weeks and ' + (i % 7) + 'days since last treatment';
      }
    },
    /**
     *  For Patient
     */
    getPatientMessage() {
      let time = (1000 * 3600 * 24);
      let dt1 = new Date().getTime();
      let RIntervalWeeks = this.R_Extended ? this.R_New_Interval : this.R_Interval;
      let LIntervalWeeks = this.L_Extended ? this.L_New_Interval : this.L_Interval;
      let Rinter = (time * 7) * RIntervalWeeks;
      let Linter = (time * 7) * LIntervalWeeks;
      let rnDt1, rnDt2, rnDt3, lnDt1, lnDt2, lnDt3;

      if (this.R_LastDate != null) {
        let Rdt2 = new Date(this.R_LastDate).getTime();
        if (this.R_TreatedToday) {
          rnDt1 = new Date(dt1 + (Rinter * 1));
          rnDt2 = new Date(dt1 + (Rinter * 2));
          rnDt3 = new Date(dt1 + (Rinter * 3));
        } else {
          rnDt1 = new Date(Rdt2 + (Rinter * 1));
          rnDt2 = new Date(Rdt2 + (Rinter * 2));
          rnDt3 = new Date(Rdt2 + (Rinter * 3));
        }
      }

      if (this.L_LastDate != null) {
        let Ldt2 = new Date(this.L_LastDate).getTime();
        if (this.L_TreatedToday) {
          lnDt1 = new Date(dt1 + (Linter * 1));
          lnDt2 = new Date(dt1 + (Linter * 2));
          lnDt3 = new Date(dt1 + (Linter * 3));
        } else {
          lnDt1 = new Date(Ldt2 + (Linter * 1));
          lnDt2 = new Date(Ldt2 + (Linter * 2));
          lnDt3 = new Date(Ldt2 + (Linter * 3));
        }
      }

      let nextTreatmentDates = [];

      if (this.R_Enable && this.R_LastDate != null) {
        const newEntry1 = {
          date: rnDt1.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" }),
          description: 'Right',
          interval: RIntervalWeeks
        };
        nextTreatmentDates.push(newEntry1);  // Add the date and description to the array

        const newEntry2 = {
          date: rnDt2.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" }),
          description: 'Right',
          interval: RIntervalWeeks
        };
        nextTreatmentDates.push(newEntry2);  // Add the date and description to the array

        const newEntry3 = {
          date: rnDt3.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" }),
          description: 'Right',
          interval: RIntervalWeeks
        };
        nextTreatmentDates.push(newEntry3);  // Add the date and description to the array
      }

      if (this.L_Enable && this.L_LastDate != null) {
        const newEntry4 = {
          date: lnDt1.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" }),
          description: 'Left',
          interval: LIntervalWeeks
        };
        nextTreatmentDates.push(newEntry4);  // Add the date and description to the array

        const newEntry5 = {
          date: lnDt2.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" }),
          description: 'Left',
          interval: LIntervalWeeks
        };
        nextTreatmentDates.push(newEntry5);  // Add the date and description to the array

        const newEntry6 = {
          date: lnDt3.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" }),
          description: 'Left',
          interval: LIntervalWeeks
        };
        nextTreatmentDates.push(newEntry6);  // Add the date and description to the array
      }

      if (nextTreatmentDates.length > 0)
      {
        nextTreatmentDates.sort((a, b) => new Date(a.date) - new Date(b.date)).slice(0, 3);  // Sort the array by date ascending

        let p1, p2, p3;
        if (nextTreatmentDates?.length >= 3) {
          p1 = `${nextTreatmentDates[0].date} - ${nextTreatmentDates[0].description} ${nextTreatmentDates[0].interval} weekly`;
          p2 = `${nextTreatmentDates[1].date} - ${nextTreatmentDates[1].description} ${nextTreatmentDates[1].interval} weekly`;
          p3 = `${nextTreatmentDates[2].date} - ${nextTreatmentDates[2].description} ${nextTreatmentDates[2].interval} weekly`;
        }

        // next 3 subsequent date
        return `${p1}\n${p2}\n${p3}`;
      }
      else
      {
        return "";
      }
    },
    /**
     * For Clinician
     */
    getDoctorMessage(date) {
      let time = (1000 * 3600 * 24);
      let dt1 = new Date().getTime();
      let result = "";

      // paragraph 1
      if (this.R_Enable && this.R_LastDate != null && this.R_Interval != null) {
        let dt2 = new Date(this.R_LastDate).getTime();
        let delta = Math.floor(Math.abs(dt1 - dt2) / time);

        result += `Right injection was ${this.getDayCounterMessage(delta)} ago at ${this.R_Interval} weekly interval.\n`;
      }

      // paragraph 2
      if (this.L_Enable && this.L_LastDate != null && this.L_Interval != null) {
        let dt2 = new Date(this.L_LastDate).getTime();
        let delta = Math.floor(Math.abs(dt1 - dt2) / time);

        result += `Left injection was ${this.getDayCounterMessage(delta)} ago at ${this.L_Interval} weekly interval.\n`;
      }

      if (result != "") result += "\n";

      // paragraph 3
      if (this.R_Enable) {
        result += this.R_TreatedToday ? "Right eye was treated today.\n" : "Right eye was not treated today.\n";
      }

      // paragraph 4
      if (this.L_Enable) {
        result += this.L_TreatedToday ? "Left eye was treated today.\n" : "Left eye was not treated today.\n";
      }

      if (result != "") result += "\n";

      // paragraph 6 & 7
      let p6;
      let p7;
      let RIntervalWeeks = this.R_Extended ? this.R_New_Interval : this.R_Interval;
      let LIntervalWeeks = this.L_Extended ? this.L_New_Interval : this.L_Interval;
      let Rinter = (time * 7) * RIntervalWeeks;
      let Linter = (time * 7) * LIntervalWeeks;
      

      if (this.R_Enable && this.R_LastDate != null && this.R_Interval != null) {
        let dt2 = new Date(this.R_LastDate).getTime();
        let nextDt = this.R_TreatedToday ? new Date(dt1 + Rinter) : new Date(dt2 + Rinter);
        let delta = Math.floor(Math.abs(dt1 - nextDt) / time);
        let RSuffix = this.R_Extended ? `(Changed on ${new Date().toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" })})` : "";

        p6 = `Right in ${this.getDayCounterMessage(delta)} on ${nextDt.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" })} at ${RIntervalWeeks} weekly interval. ${RSuffix}\n`;
      }

      if (this.L_Enable && this.L_LastDate != null && this.L_Interval != null) {
        let dt2 = new Date(this.L_LastDate).getTime();
        let nextDt = this.L_TreatedToday ? new Date(dt1 + Linter) : new Date(dt2 + Linter);
        let delta = Math.floor(Math.abs(dt1 - nextDt) / time);
        let LSuffix = this.L_Extended ? `(Changed on ${new Date().toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" })})` : "";

        p7 = `Left in ${this.getDayCounterMessage(delta)} on ${nextDt.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" })} at ${LIntervalWeeks} weekly interval. ${LSuffix}\n`;
      }

      if (p6 != null || p7 != null) {
        result += `Next Treatment Dates:\n${p6 ? p6 : ""}${p7 ? p7 : ""}`;
      }

      return result;
    },
    /**
     *  RightEye last treatment
     */
    getRightEyeMsg1() {
      let dt1 = new Date().getTime();
      let time = (1000 * 3600 * 24);
      if (this.R_LastDate == null) {
        return '';
      } else {
        let dt2 = new Date(this.R_LastDate).getTime();
        let delta = Math.floor(Math.abs(dt1 - dt2) / time);

        return `${this.getDayCounterMessage(delta)} since last treatment`;
      }
    },
    /**
     *  RightEye next treatment
     */
    getRightEyeMsg2() {
      let time = (1000 * 3600 * 24);
      let dt1 = new Date().getTime();
      let inter = this.R_Extended ? (time * 7) * this.R_New_Interval : (time * 7) * this.R_Interval;

      if (this.R_LastDate == null) {
        return "";
      } else {
        let dt2 = new Date(this.R_LastDate).getTime();
        let nextDt = this.R_TreatedToday ? new Date(dt1 + inter) : new Date(dt2 + inter);
        let delta = Math.floor(Math.abs(dt1 - nextDt) / time);

        return `Next treatment in ${this.getDayCounterMessage(delta)} on ${nextDt.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" })}`;
      }
    },
    /**
     *  LeftEye last treatment
     */
    getLeftEyeMsg1() {
      let dt1 = new Date().getTime();
      let time = (1000 * 3600 * 24);
      if (this.L_LastDate == null) {
        return '';
      } else {
        let dt2 = new Date(this.L_LastDate).getTime();
        let delta = Math.floor(Math.abs(dt1 - dt2) / time);

        return `${this.getDayCounterMessage(delta)} since last treatment`;
      }
    },
    /**
     *  LeftEye next treatment
     */
    getLeftEyeMsg2() {
      let time = (1000 * 3600 * 24);
      let dt1 = new Date().getTime();
      let inter = this.L_Extended ? (time * 7) * this.L_New_Interval : (time * 7) * this.L_Interval;

      if (this.L_LastDate == null) {
        return ""
      } else {
        let dt2 = new Date(this.L_LastDate).getTime();
        let nextDt = this.L_TreatedToday ? new Date(dt1 + inter) : new Date(dt2 + inter);
        let delta = Math.floor(Math.abs(dt1 - nextDt) / time);

        return `Next treatment in ${this.getDayCounterMessage(delta)} on ${nextDt.toLocaleDateString('en-au', { year: "numeric", month: "short", day: "numeric" })}`;
      }
    },
  }
}

</script>
<style>
.theme--light.v-application {
  background: #f2f5f8;
}

.my-label-description {
  margin-top: 20px;
  font-weight: bold;
  padding-right: 10px;
}

.my-compact {
  padding: 5px;
}

.my-heading-1 {
  margin-bottom: 20px;
  color: #0096c7;
}

.my-border-rounded {
  border: 1px solid gray;
  border-radius: 20px;
  padding: 30px;
}

.my-border-rounded-2 {
  border: 1px solid gray;
  border-radius: 20px;
  padding: 10px;
}

.multiline {
  white-space: pre-line;
}

v-lable1 {
  border: 5px black;
  border-radius: 5px;
  padding: 5px;
}
</style>
